import React from 'react';

import './index.less';

import RegisterForm from '../components/RegisterForm'

export default class RegisterPage extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="content">
                    <div className="main">
                        <RegisterForm />
                    </div>
                </div>
            </div>
        )
    }
}