import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Input, 
    Button, 
    Radio,
    Switch,
} from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

@inject('info')
@observer
class UserEditForm extends React.Component {

    state = {
        user: {},
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const userId = this.props.userId
        this.props.info.getProjects()
        this.props.info.getRoles()
        if(!_.isUndefined(userId)) {
            const data = await this.props.info.getUser(userId)
            this.setState({
                user: data,
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const userId = this.props.userId             
                if(!_.isUndefined(userId)) {
                    await this.props.info.updateUser(userId, {user: values})
                } else {
                    await this.props.info.createUser({user: values})
                }
                history.push("/dashboard/users")
            }
        });
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/users")
    }

    handleChange = (value) => {
        const data = this.props.info.getRolesAuth(value)
        this.setState({
            auth: data
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { user } = this.state

        return (
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 8 }}
                >
                    <FormItem
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                        label="账号状态"
                    >
                        {getFieldDecorator('status', {
                            initialValue: user.status||"register",
                            rules: [{ required: true, message: 'Please select your status!' }],
                        })(
                            <RadioGroup>
                                <RadioButton value="register">register</RadioButton>
                                <RadioButton value="normal">normal</RadioButton>
                                <RadioButton value="frozed">frozed</RadioButton>
                            </RadioGroup>
                        )}
                    </FormItem>
                    <FormItem 
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                        label="昵称"
                    >
                        {getFieldDecorator('nickname', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入昵称',
                                },
                            ],
                            initialValue: user.nickname
                        })(<Input placeholder="请输入昵称" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                        label="用户名"
                    >
                        {getFieldDecorator('username', {
                            rules: [{
                                required: true, message: 'Please input username',
                            }],
                            initialValue: user.username
                        })(
                            <Input placeholder="请输入用户名"/>
                        )}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 12 }}
                        label="Password"
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    validator: this.validateToNextPassword,
                                }
                            ],
                        })(
                            <Input type="password" placeholder="请输入密码"/>
                        )}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 12 }}
                        label="Confirm Password"
                    >
                        {getFieldDecorator('password_confirmation', {
                            rules: [{
                                validator: this.compareToFirstPassword,
                            }],
                        })(
                            <Input type="password" onBlur={this.handleConfirmBlur} placeholder="请再次输入密码"/>
                        )}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                        label="Master"
                    >
                        {getFieldDecorator('master', {
                            initialValue: user.master || false,
                            valuePropName: 'checked' 
                        })(
                            <Switch checkedChildren="master" unCheckedChildren="normal" />
                        )}
                    </FormItem>
                    <FormItem
                        wrapperCol={{ span: 8, offset: 5 }}
                    >
                        <Button onClick={this.handleBack}>返回上一级</Button>
                        <Button type="primary" style={{marginLeft:'10px'}} htmlType="submit">提交</Button>
                    </FormItem>
                </Form>
        )
    }
}

export default  Form.create()(UserEditForm);