import React from 'react';
import { inject, observer } from 'mobx-react'

import {
    Card,
    List,
    Tag,
    Button,
    Form,
    PageHeader
} from 'antd'

import history from '../../router/history'

const FormItem = Form.Item;

@inject('info')
@observer
export default class Projects extends React.Component {

    componentDidMount() {
        this.props.info.getProjects()
    }

    handleNewClick = async (e) => {
        e.preventDefault();
        history.push("/dashboard/projects/new")
    }

    handleEditClick = async (e, key) => {
        e.preventDefault();
        history.push("/dashboard/projects/"+key+"/edit")
    }

    handleSyncClick = async (e, key) => {
        await this.props.info.syncProject(key)
    }

    handleDeleteClick = (e,key) => {
        e.preventDefault()
        this.props.info.destroyProject(key)
    }

    render() {
        const { projects } = this.props.info

        return (
            <div>
                <PageHeader
                    title="项目管理"
                    subTitle="This is a subtitle"
                />
                <div 
                    style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}
                >
                    <Card bordered={false} >
                        <div style={{marginBottom: '20px'}}>
                            <Button icon="plus" type="primary" onClick={this.handleNewClick}>
                                新建
                            </Button>
                        </div>
                        <div>{projects.slice().length || 0}个项目</div>

                        <List
                            itemLayout="vertical"
                            dataSource={projects.slice()}
                            renderItem={item => (
                                <List.Item 
                                    title={item.name}
                                    actions={[
                                        <a href='javascript:;' onClick={(e)=>this.handleSyncClick(e, item.id)}>Sync</a>,
                                        <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, item.id)}>Edit</a>, 
                                        <a href='javascript:;' onClick={(e)=>this.handleDeleteClick(e, item.id)}>Delete</a>
                                    ]}
                                    
                                >
                                <List.Item.Meta
                                    title={item.name}
                                    description={item.sign}
                                />
                                    权限: {item.auth.map(key=>(
                                        <Tag key={key[0]}>{key[1]}</Tag>
                                    ))}
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            </div>
        )
    }
}

