import React from 'react';

import {
    Card,
    PageHeader
} from 'antd'

export default class UserShow extends React.Component {

    render() {
        return(
           <div>
                <PageHeader
                    // onBack={() => null}
                    title="新用户"
                    subTitle="This is a subtitle"
                />
                <div
                    style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}
                >
                    <Card bordered={false}>
                        111111111
                    </Card>
                </div>
            </div>
        )
    }
}