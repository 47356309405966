import React from 'react';
import UserSettingForm from '../../components/UserSettingForm'
import AvatarUpload from '../../components/AvatarUpload'

import UserPasswordForm from '../../components/UserPasswordForm'

import {
    Card,
    Tabs,
    PageHeader
} from 'antd'

const TabPane = Tabs.TabPane;

class UserSetting extends React.Component {
    render() {
        return (
           <div>
                <PageHeader
                    // onBack={() => null}
                    title="新用户"
                    subTitle="This is a subtitle"
                />
                <div
                    style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}
                >
                    <Card bordered={false}>
                        <div style={{
                            flex: '1 1',
                            padding: '8px 40px'
                        }}>
                            <Tabs 
                                defaultActiveKey="base"
                                tabPosition={"left"}
                                onChange={this.handleChange}
                            >
                                <TabPane tab="基础设置" key="base">
                                    <div style={{
                                        display: 'flex',
                                        paddingTop: '12px',
                                    }}>
                                        <div style={{
                                            width: '300px',
                                            marginTop: 8 
                                        }}>
                                            <UserSettingForm />
                                        </div>
                                        <div style={{
                                            flex: '1 1',
                                            paddingLeft: '50px'
                                        }}>
                                            <AvatarUpload />
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="密码设置" key="2">
                                    <div style={{
                                        display: 'flex',
                                        paddingTop: '12px',
                                    }}>
                                        <div style={{
                                            width: '300px',
                                            marginTop: 8 
                                        }}>
                                            <UserPasswordForm />
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="账号退出" key="3">
                                    
                                
                                </TabPane>
                            </Tabs>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}

export default UserSetting;