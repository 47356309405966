import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Tabs, 
    PageHeader
} from 'antd';

const TabPane = Tabs.TabPane;

function callback(key) {
  console.log(key);
}

export default class Info extends React.Component {

    render() {
        return (
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="编辑项目"
                    subTitle="This is a subtitle"
                />
                <div
                    style={{
                        margin: '24px 24px 0',
                        width: '1100px'
                    }}
                >
                    <div>1111111111</div>
                </div>
            </div>
        )
    }
}