// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.less';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './router';

import { Provider } from 'mobx-react'
import store from './store'

import "./index.less"
import 'ant-design-pro/dist/ant-design-pro.css';

ReactDOM.render(
    <Provider {...store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);

serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}