import axios from 'axios'

import api from '../config/api'
import ErrorService from '../services/ErrorService'

class ApiService {

    async apiCall(url, method = 'GET', token = false, params = null, query = null) {
        let payload = {
            url : url,
            method,
            query,
            headers: this.buildHeaders(token),
        }
        if (query) {
            payload.params = query;
        }
        if (params) {
            payload.data = params;
        }
        const res = await axios.request(payload).catch((error)=>{
            return error.response
        })
        return res;
    }

    buildHeaders(token = false) {
        let headers = {};
        headers['Content-type'] = 'application/json'

        if (token) {
            headers['Authorization'] = `${token}`
        }
        return headers;
    }

    // 权限----------------------------------------
    async login(params) {
        const url = `${api.auth}/login`
        const res = await this.apiCall(url, 'POST', false, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // 注册用户
    async registerUser(params) {
        const url = `${api.auth}/register`
        const res = await this.apiCall(url, 'POST', false, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async syncData(token, params) {
        const url = `${api.auth}/sync`
        const res = await this.apiCall(url, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // user---------------------------------
    // 获取所有用户
    async getUsers(token) {
        const res = await this.apiCall(api.user, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getUser(token, key) {
        const url = `${api.user}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createUser(token, params) {
        const res = await this.apiCall(api.user, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateUser(token, key, params) {
        const url = `${api.user}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateUserSetting(token, params) {
        const url = `${api.user}/update_setting`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyUser(token, key) {
        const url = `${api.user}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // projects--------------------------------------------------------------------
    async getProjects(token) {
        const res = await this.apiCall(api.project, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getProject(token, key) {
        const url = `${api.project}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }
    
    async createProject(token, params) {
        const res = await this.apiCall(api.project, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateProject(token, key, params) {
        const url = `${api.project}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyProject(token, key) {
        const url = `${api.project}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async syncProject(token, key) {
        const url = `${api.project}/${key}/sync`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // role--------------------------------------------------------------------

    async getRole(token, key) {
        const url = `${api.role}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getRoles(token) {
        const res = await this.apiCall(api.role, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createRole(token, params) {
        const res = await this.apiCall(api.role, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateRole(token, key, params) {
        const url = `${api.role}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyRole(token, key) {
        const url = `${api.role}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // url-----------------------------------------------------------------
    async getUrl(token, key) {
        const url = `${api.url}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getUrls(token) {
        const res = await this.apiCall(api.url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createUrl(token, params) {
        const res = await this.apiCall(api.url, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateUrl(token, key, params) {
        const url = `${api.url}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyUrl(token, key) {
        const url = `${api.url}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }
}

export default new ApiService()
