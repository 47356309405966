import React, { Suspense } from 'react';
import { inject, observer } from 'mobx-react'
import classNames from 'classnames';
import { 
    Avatar,
    Layout,
    Menu,
    Icon,
    Dropdown
} from 'antd';

import PageLoading from '../PageLoading';

import styles from './SiderMenu.module.less';
import history from '../../router/history'

const BaseMenu = React.lazy(() => import('./BaseMenu'));

const { Sider } = Layout;

@inject('admin')
@observer
export default class SiderMenu extends React.Component {

    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    }

    setting = () => {
        history.push("/dashboard/users/setting")
    }

    logout = () => {
        this.props.admin.logout()
        history.push("/login")
    }

    render() {
        const { logo } = this.props;

        const siderClassName = classNames(styles.sider, 
            styles.fixSiderBar,
            styles.light,
        );

        const avatar = _.isEmpty(this.props.admin.user.avatar) 
            ? "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png"
            : this.props.admin.avatar

        const nickname = _.isEmpty(this.props.admin.user.nickname) 
            ? "无名氏"
            : this.props.admin.user.nickname

        return(
            <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                width={256}
                theme={"light"}
                className={siderClassName}
            >   
                <div className={styles.logo} id="logo">
                    <img src={logo} alt="logo" />
                    <h1>{"测试"}</h1>
                </div>

                <div className={classNames(styles.action, styles.account)}>
                    <a href='javascript:;' onClick={()=>this.setting()}>
                        <Avatar className="avatar" src={avatar} />
                        <h1>{nickname}</h1>
                    </a>
                </div>


                <Suspense fallback={<PageLoading />}>
                    <BaseMenu
                        mode="inline"
                        style={{ padding: '16px 0', width: '100%' }}
                        {...this.props}
                    />
                </Suspense>
                <div className={classNames(styles.logout, styles.action)} id="logout">
                    <a href='javascript:;' onClick={()=>this.logout()}>
                        <Icon type="logout" className={"icon"} ></Icon>
                        <h2>退出</h2>
                    </a>
                </div>
            </Sider>
        )
    }

}