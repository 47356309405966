import config from "./index"

const api = {
    auth: config.SERVER_URL + "/authentication",
    user: config.SERVER_URL + "/users",
    project: config.SERVER_URL + "/projects",
    role: config.SERVER_URL + "/roles",
    url: config.SERVER_URL + "/urls",
}

export default api;