import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Input, 
    Button, 
    Divider,
    Icon
} from 'antd';

import history from '../../router/history'
import "./index.less"

const FormItem = Form.Item;
const InputGroup = Input.Group;

@inject('info')
@observer
class ProjectForm extends React.Component {

    state = {
        project: {},
        auth: [[]]
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const projectId = this.props.projectId
        if(!_.isUndefined(projectId)) {
            const data = await this.props.info.getProject(projectId)
            this.setState({
                project: data,
                auth: data.auth || []
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const projectId = this.props.projectId             
                if(!_.isUndefined(projectId)) {
                    await this.props.info.updateProject(this.state.project.id, {project: values})
                } else {
                    await this.props.info.createProject({project: values})
                }
                history.push("/dashboard/projects")
            }
        });
    }

    removeAuth = (index) => {
        this.setState({
            auth: this.state.auth.filter((_, i) => i !== index)
        })
    }

    addAuth = () => {
        this.setState({
            auth: [...this.state.auth, []]
        })
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/projects")
    }

    handleChange = (value) => {
        const data = this.props.info.getRolesAuth(value)
        this.setState({
            auth: data
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { project, auth } = this.state

        return (
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 8 }}
                >
                    <Divider>基础信息</Divider>
                    <FormItem 
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="名称"
                    >
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入名称',
                                },
                            ],
                            initialValue: project.name || ""
                        })(<Input placeholder="请输入名称" />)}
                    </FormItem>
                    <FormItem 
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 15 }}
                        label="同步网址"
                    >
                        {getFieldDecorator('url', {
                            initialValue: project.url
                        })(<Input placeholder="请输入同步网址" />)}
                    </FormItem>
                    <FormItem 
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="标志"
                    >
                        {getFieldDecorator('sign', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入标志',
                                },
                            ],
                            initialValue: project.sign||""
                        })(<Input placeholder="请输入标志" />)}
                    </FormItem>
                    <Divider>权限设置</Divider>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                        label="权限"
                    >
                        {
                            auth.map((item, index)=>(
                                <div style={{ marginLeft: 0 }} key={index}>
                                    <Form.Item
                                        style={{ display: 'inline-block', width: 'calc(30% - 12px)' }}
                                    >
                                        {getFieldDecorator(`auth[${index}][0]`, {
                                            initialValue: item[0] || ""
                                        })(
                                            <Input placeholder="请输入权限标志" />
                                        )}
                                        </Form.Item>
                                        <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>
                                            -
                                        </span>
                                        <Form.Item
                                            style={{ display: 'inline-block', width: 'calc(30% - 12px)' }}
                                        >
                                        {getFieldDecorator(`auth[${index}][1]`, {
                                            initialValue: item[1] || ""
                                        })(
                                            <Input placeholder="请输入权限名称" />
                                        )}
                                        </Form.Item>

                                    {auth.length > 1 ? (
                                        <Icon
                                            className="dynamic-delete-button"
                                            type="minus-circle-o"
                                            disabled={auth.length === 1}
                                            style={{ marginRight: 8 }}
                                            onClick={() => this.removeAuth(index)}
                                        />
                                    ) : null}
                                    {
                                        auth.length === (index+1) ? (
                                            <Icon
                                                style={{ marginRight: 4 }}
                                                className="dynamic-delete-button"
                                                type="plus-circle"
                                                onClick={() => this.addAuth()}
                                            />
                                        ) : null
                                    }
                                </div>
                                
                            ))
                        }
                    
                    </FormItem>
                    <FormItem
                        wrapperCol={{ span: 8, offset: 6 }}
                    >
                        <Button onClick={this.handleBack}>返回上一级</Button>
                        <Button type="primary" style={{marginLeft:'10px'}} htmlType="submit">提交</Button>
                    </FormItem>
                </Form>
        )
    }
}

export default  Form.create()(ProjectForm);