import React from 'react';
import { inject, observer } from 'mobx-react'

import {
    Card,
    Table,
    Tag,
    PageHeader
} from 'antd'

import history from '../../router/history'
import UserAvatar from '../../components/UserAvatar'

const { Column } = Table;

@inject('info')
@observer
export default class AuthList extends React.Component {

    componentDidMount() {
        this.props.info.getUsers()
    }

    handleEditClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/users/"+key+"/auth_edit")
    }

    render() {
        const { users } = this.props.info

        return (
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="用户管理"
                    subTitle="This is a subtitle"
                />
                <div 
                    style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}
                >
                    <Card bordered={false} >
                        <div>
                            <div>{users.slice().length || 0}个用户</div>
                            <Table 
                                dataSource={users.slice()}
                                pagination={{
                                    pageSize: 98
                                }}
                                rowKey={user=>user.id}
                            >   
                                <Column
                                    title="头像"
                                    key="avatar"
                                    width={30}
                                    render={(data, user) => {
                                        return (
                                            <UserAvatar avatar_url={user.avatar}/>
                                        )
                                    }}
                                    />
                                <Column
                                    title="状态"
                                    key="status"
                                    width={30}
                                    render={(data, user) => (
                                        <Tag>{user.status}</Tag>
                                    )}
                                    />
                                <Column
                                    title="角色"
                                    key="group_name"
                                    width={150}
                                    render={(data, user) => (
                                        user.roles.map(item=>(
                                            <Tag key={item.role_name}>{item.role_name||""}</Tag>
                                        ))
                                    )}
                                    />
                                <Column
                                    title="昵称"
                                    key="nickname"
                                    width={200}
                                    render={(data, user) => {
                                        return (
                                            <span>{user.nickname}</span>
                                        )
                                    }}
                                    />
                                <Column
                                    title="用户名"
                                    key="username"
                                    width={200}
                                    render={(data, user) => {
                                        return (
                                            <span>{user.username}</span>
                                        )
                                    }}
                                    />
                                <Column
                                    title="Action"
                                    key="action"
                                    render={(data) => (
                                        <span>
                                        <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, data.id)}>编辑权限</a>
                                        </span>
                                    )}
                                    />
                            </Table>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}