import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Input, 
    Button, 
} from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;

@inject('admin')
@observer
class UserPasswordForm extends React.Component {

    state = {
        confirmDirty: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values)
                this.props.admin.updateSetting({user: values})
            }
        })
    }

    handleBack = (e) => {
        e.preventDefault();
        history.goBack()
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password_confirmation'], { force: true });
        }
        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { user } = this.props.admin || {}

        return (
            <Form onSubmit={this.handleSubmit} >
                <FormItem
                    label="密码"
                >
                    {getFieldDecorator('password', {
                        rules: [{
                        validator: this.validateToNextPassword,
                        }],
                    })(
                        <Input type="password" placeholder="请输入密码"/>
                    )}
                </FormItem>
                <FormItem
                    label="确认密码"
                >
                    {getFieldDecorator('password_confirmation', {
                        rules: [{
                        validator: this.compareToFirstPassword,
                        }],
                    })(
                        <Input type="password" onBlur={this.handleConfirmBlur} placeholder="请再次输入密码"/>
                    )}
                </FormItem>
                <FormItem
                    >
                    <Button onClick={this.handleBack}>返回</Button>
                    <Button type="primary" style={{marginLeft:'10px'}} htmlType="submit">提交</Button>
                </FormItem>
            </Form>
        )
    }
}

export default  Form.create()(UserPasswordForm);