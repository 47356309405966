import React from 'react';

import UserEditForm from '../../components/UserEditForm'

import {
    Card,
    PageHeader
} from 'antd'

export default class UserNew extends React.Component {

    render() {
        return(
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="新用户"
                    subTitle="This is a subtitle"
                />
                <div
                    style={{
                        margin: '24px 24px 0',
                        width: '800px'
                    }}
                >
                    <Card bordered={false}>
                        <UserEditForm />
                    </Card>
                </div>
            </div>
        )
    }
}