import _ from 'lodash'
import React from 'react';

import { 
    Avatar, 
} from 'antd';

export default class UserAvatar extends React.Component {

    render() {
        const avatar = _.isEmpty(this.props.avatar_url) 
            ? "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png"
            : this.props.avatar_url
        const size = this.props.size ? this.props.size : "default"
        return (
            <Avatar className="avatar" src={avatar} size={size} />
        )
    }
}