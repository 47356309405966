import React from 'react';

import UserEditForm from '../../components/UserEditForm'

import {
    Card,
    PageHeader
} from 'antd'

export default class UserEdit extends React.Component {

    render() {
        return (
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="编辑用户"
                    subTitle="This is a subtitle"
                />
                <div style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}>
                    <Card bordered={false}>
                        <UserEditForm userId={this.props.match.params.id}/>
                    </Card>
                </div>
            </div>
        )
    }
}