import React from 'react';
import { inject, observer } from 'mobx-react'

import {
    Card,
    Tag,
    Button,
    List,
    PageHeader
} from 'antd'

import history from '../../router/history'

@inject('info')
@observer
class Roles extends React.Component {

    componentDidMount() {
        this.props.info.getRoles()
    }

    handleNewClick = (e) => {
        history.push('/dashboard/roles/new')
    }

    handleEditClick = (e, key) => {
        history.push('/dashboard/roles/'+key+"/edit")
    }

    handleDeleteClick = (e, key) => {
        this.props.info.destroyRole(key)
    }
 
    render() {
        const { roles } = this.props.info 

        return (
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="角色管理"
                    subTitle="This is a subtitle"
                />
                <div 
                    style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}
                >
                    <Card bordered={false} >
                        <div style={{marginBottom: '20px'}}>
                            <Button icon="plus" type="primary" onClick={this.handleNewClick}>
                                新建
                            </Button>
                        </div>
                        <div>{roles.slice().length || 0}个角色</div>
                        <List
                            itemLayout="vertical"
                            dataSource={roles.slice()}
                            renderItem={role => (
                                <List.Item 
                                    actions={[
                                        <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, role.id)}>Edit</a>, 
                                        <a href='javascript:;' onClick={(e)=>this.handleDeleteClick(e, role.id)}>Delete</a>
                                    ]}
                                    
                                >
                                <List.Item.Meta
                                    title={role.role_name}
                                    description={
                                        Object.keys(role.auth_name).map(key=>(
                                            _.isEmpty(role.auth_name[key]) ?
                                            "" :
                                            <div key={key}>{key} : {
                                                role.auth_name[key].map(item=>(
                                                    <Tag key={item}>{item}</Tag>
                                                ))
                                            }</div>
                                        ))
                                    }
                                />
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            </div>
        )
    }
}

export default Roles;