import React from 'react';
import { inject, observer } from 'mobx-react'

import {
    Card,
    Table,
    Button,
    Divider,
    Tag,
    PageHeader
} from 'antd'

import history from '../../router/history'
import UserAvatar from '../../components/UserAvatar'

const { Column } = Table;

@inject('info')
@observer
export default class Users extends React.Component {

    state = {
        selectedRowKeys: []
    }

    componentDidMount() {
        this.props.info.getUsers()
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ 
            selectedRowKeys
        });
    }

    handleEditClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/users/"+key+"/edit")
    }

    handleNewUser = (e) => {
        e.preventDefault()
        history.push("/dashboard/users/new")
    }

    handleDeleteClick = (e,key) => {
        e.preventDefault()
        this.props.info.deleteUser(key)
    }

    render() {
        const { users } = this.props.info
        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="用户管理"
                    subTitle="This is a subtitle"
                />
                <div 
                    style={{
                        margin: '24px 24px 0',
                        width: '1100px'
                    }}
                >
                    <Card bordered={false} >
                        <div>
                                <div style={{marginBottom: '20px'}}>
                                    <Button icon="plus" type="primary" onClick={this.handleNewUser}>
                                        新建
                                    </Button>
                                </div>
                                <div>{users.slice().length || 0}个用户</div>
                                <Table 
                                    dataSource={users.slice()}
                                    rowSelection={rowSelection}
                                    pagination={{
                                        pageSize: 98
                                    }}
                                    rowKey={user=>user.id}
                                >   
                                    <Column
                                        title="头像"
                                        key="avatar"
                                        width={30}
                                        render={(data, user) => {
                                            return (
                                                <UserAvatar avatar_url={user.avatar}/>
                                            )
                                        }}
                                        />
                                    <Column
                                        title="状态"
                                        key="status"
                                        width={30}
                                        render={(data, user) => (
                                            <Tag>{user.status}</Tag>
                                        )}
                                        />
                                    <Column
                                        title="角色"
                                        key="group_name"
                                        width={150}
                                        render={(data, user) => (
                                            user.roles.map(item=>(
                                                <Tag key={item.role_name}>{item.role_name||""}</Tag>
                                            ))
                                        )}
                                        />
                                    <Column
                                        title="昵称"
                                        key="nickname"
                                        width={200}
                                        render={(data, user) => {
                                            return (
                                                <span>{user.nickname}</span>
                                            )
                                        }}
                                        />
                                    <Column
                                        title="用户名"
                                        key="username"
                                        width={200}
                                        render={(data, user) => {
                                            return (
                                                <span>{user.username}</span>
                                            )
                                        }}
                                        />
                                    <Column
                                        title="Action"
                                        key="action"
                                        render={(data) => (
                                            <span>
                                            <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, data.id)}>Edit</a>
                                            <Divider type="vertical" />
                                            <a href='javascript:;' onClick={(e)=>this.handleDeleteClick(e, data.id)}>Delete</a>
                                            </span>
                                        )}
                                        />
                                </Table>
                            </div>
                    </Card>
                </div>
            </div>
        )
    }
}