import React from 'react';
import { inject, observer } from 'mobx-react'
import { Form, Input, Button, Row, Col } from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;

@inject('admin')
@observer
class RegisterForm extends React.Component {

    state = {
        confirmDirty: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.admin.registerUser({user: values})
            }
        });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password_confirmation'], { force: true });
        }
        callback();
    }

    toBack = () => {
        history.push("/login")
    }


    render () {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        label="Username"
                    >
                        {getFieldDecorator('username', {
                            rules: [ {
                            required: true, message: 'Please input username',
                            }],
                        })(
                            <Input />
                        )}

                    </FormItem>

                    <FormItem
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        label="Password"
                    >
                        {getFieldDecorator('password', {
                            rules: [{
                            required: true, message: 'Please input your password!',
                            }, {
                            validator: this.validateToNextPassword,
                            }],
                        })(
                            <Input type="password" />
                        )}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        label="Confirm Password"
                    >
                        {getFieldDecorator('password_confirmation', {
                            rules: [{
                            required: true, message: 'Please confirm your password!',
                            }, {
                            validator: this.compareToFirstPassword,
                            }],
                        })(
                            <Input type="password" onBlur={this.handleConfirmBlur} />
                        )}
                    </FormItem>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button style={{marginRight:"8px"}} type="primary" htmlType="submit">注册</Button>
                            <Button onClick={this.toBack}>返回</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

export default  Form.create()(RegisterForm);