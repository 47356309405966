import React from 'react';

import AuthEditForm from '../../components/UserEditForm/auth'

import {
    Card,
    PageHeader
} from 'antd'

export default class AuthEdit extends React.Component {

    render() {
        return (
            <div>
                <PageHeader
                    title="编辑用户权限"
                    subTitle="This is a subtitle"
                />
                <div style={{
                        margin: '24px 24px 0',
                        width: '800px'
                    }}>
                    <Card bordered={false}>
                        <AuthEditForm userId={this.props.match.params.id}/>
                    </Card>
                </div>
            </div>
        )
    }
}