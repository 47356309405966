import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Upload, 
    Icon, 
    Button
} from 'antd';

import api from '../../config/api'
import StorageService from '../../services/StorageService'

import './index.less'

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

@inject('admin')
@observer
class AvatarUpload extends React.Component {

    state = {
        loading: false,
        header: {
            'Authorization': `Token ${StorageService.getToken()}`
        }
    };

    handleChange = (info) => {

        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }

        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl => {
                this.setState({
                    loading: false,
                })
                this.props.admin.updateAvatar(info.file.response.avatar)
            });
        }
    }

    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const imageUrl = this.props.admin.avatar;
        const userId = this.props.admin.user.id

        return (
            <div>
                <div style={{
                    height: '22px',
                    fontSize: '14px',
                    color: 'rgba(0,0,0,.85)',
                    lineHeight: '22px',
                    marginBottom: '8px',
                }}>头像</div>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={api.user+"/avatar"}
                    headers={this.state.header}
                    onChange={this.handleChange}
                    data={{
                        id:1
                    }}
                >   
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                </Upload>
            </div>
        )
    }
}

export default AvatarUpload;