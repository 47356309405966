import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Button, 
    Checkbox,
    Divider,
    Row,
    Col
} from 'antd';

import TagSelect from 'ant-design-pro/lib/TagSelect';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import history from '../../router/history'

const FormItem = Form.Item;
const { Description } = DescriptionList;

@inject('info')
@observer
class AuthEditForm extends React.Component {

    state = {
        user: {},
        auth: {}
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const userId = this.props.userId
        this.props.info.getProjects()
        this.props.info.getRoles()
        if(!_.isUndefined(userId)) {
            const data = await this.props.info.getUser(userId)
            this.setState({
                user: data,
                auth: data.auth||{},
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const userId = this.props.userId             
                if(!_.isUndefined(userId)) {
                    await this.props.info.updateUser(userId, {user: values})
                }
                history.push("/dashboard/users/users_auth")
            }
        });
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/users/users_auth")
    }

    handleChange = (value) => {
        const data = this.props.info.getRolesAuth(value)
        this.setState({
            auth: data
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { projects, roles } = this.props.info
        const { user, auth } = this.state

        return (
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 8 }}
                >
                    <DescriptionList size="large" title={user.nickname}>
                        <Description term="状态">{user.status}</Description>
                    </DescriptionList>
                    <Divider orientation="left">角色</Divider>
                    <FormItem>
                        {getFieldDecorator('role_ids', {
                            initialValue: user.role_ids||[],
                        })(
                            <TagSelect
                                onChange={this.handleChange}
                            >
                                {
                                    roles.map((role, index)=> (
                                        <TagSelect.Option key={role.id} value={role.id}>{role.role_name}</TagSelect.Option>
                                    ))
                                }
                            </TagSelect>
                        )}
                    </FormItem>
                    <Divider orientation="left">权限</Divider>
                        {
                            projects.map(project=>(
                                <FormItem
                                    key={project.id}
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 16 }}
                                    label={project.name}
                                >
                                {getFieldDecorator(`auth[${project.sign}]`, {
                                    initialValue: auth[project.sign] || [],
                                })(
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row>
                                                {
                                                    project.auth.map((item, index)=> (
                                                        <Col key={item[0]} span={8}><Checkbox value={item[0]}>{item[1]}</Checkbox></Col>
                                                    ))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </FormItem>
                            ))
                        }

                    <FormItem
                        wrapperCol={{ span: 8, offset: 3 }}
                    >
                        <Button onClick={this.handleBack}>返回上一级</Button>
                        <Button type="primary" style={{marginLeft:'10px'}} htmlType="submit">提交</Button>
                    </FormItem>
                </Form>
        )
    }
}

export default  Form.create()(AuthEditForm);