import _ from 'lodash'
import { observable, action, runInAction, toJS } from 'mobx'

import ApiService from '../services/ApiService'
import StorageService from '../services/StorageService'

// import history from '../router/history'

class Info {
    @observable users = []
    @observable projects = []
    @observable roles = []
    @observable urls = []

    // 同步
    syncData = async (params) => {
        const data = await ApiService.syncData(StorageService.getToken(), params)
        return data
    }

    // project--------------------------------------------------
    @action
    getProjects = async () => {
        const data = await ApiService.getProjects(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.projects = data
            })
        }
    }

    getProject = async (key) => {
        const data = await ApiService.getProject(StorageService.getToken(), key)
        return data
    }

    @action
    createProject = async (params) => {
        const data = await ApiService.createProject(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.projects.push(data)
            })
        }
    }

    @action
    updateProject = async (key, params) => {
        const data = await ApiService.updateProject(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.projects, {sign: data.sign})
            if(index > -1) {
                runInAction(()=>{
                    this.projects.splice(index, 1, data)
                })
            }
        }
    }

    @action 
    destroyProject = async (key) => {
        const data = await ApiService.destroyProject(StorageService.getToken(), key)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.projects, {sign: data.sign})
            if(index > -1) {
                runInAction(()=>{
                    this.projects.splice(index, 1)
                })
            }
        }
    }

    @action
    syncProject = async (key) => {
        const data = await ApiService.syncProject(StorageService.getToken(), key)
        console.log(data)
    }

    // user------------------------------------------------------------------------------------
    getUser = async (key) => {
        const user = await ApiService.getUser(StorageService.getToken(), key)
        console.log(user)
        return user
    }

    @action
    getUsers = async () => {
        const data = await ApiService.getUsers(StorageService.getToken())
        if(!_.isNull(data)){
            this.users = data
        }
    }

    @action
    createUser = async (params) => {
        const data = await ApiService.createUser(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            this.users.push(data)
        }
    }

    @action
    deleteUser = async (key) => {
        await ApiService.destroyUser(StorageService.getToken(), key)
        const index = _.findIndex(this.users, {id: key})
        if(index > -1) {
            this.users.splice(index,1)
        }
    }

    @action
    updateUser = async (key, params) => {
        const data = await ApiService.updateUser(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.users, {id: data.id})
            if(index > -1) {
                this.users.splice(index, 1, data)
            }
        }
    }

    // role------------------------------------------------------------------------------------
    getRolesAuth = (ids) => {
        const result = {}
        ids.map(id => {
            const index = _.findIndex(this.roles, {id: parseInt(id)})
            if(index > -1) {
                const auth = toJS(this.roles[index].auth)
                Object.keys(auth).map(key=>{
                    const result_key = result[key]||[]
                    const auth_key = auth[key]||[]
                    result[key] = Array.from(new Set([...result_key, ...auth_key]))
                })
            } 
        })
        return result
    }

    getRole = async (key) => {
        const role = await ApiService.getRole(StorageService.getToken(), key)
        return role
    }

    @action
    getRoles = async () => {
        const data = await ApiService.getRoles(StorageService.getToken())
        if(!_.isNull(data)){
            this.roles = data
        }
    }

    @action
    createRole = async (params) => {
        const data = await ApiService.createRole(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            this.roles.push(data)
        }
    }

    @action
    destroyRole = async (key) => {
        await ApiService.destroyRole(StorageService.getToken(), key)
        const index = _.findIndex(this.roles, {id: key})
        if(index > -1) {
            this.roles.splice(index,1)
        }
    }

    @action
    updateRole = async (key, params) => {
        const data = await ApiService.updateRole(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.roles, {id: data.id})
            if(index > -1) {
                this.roles.splice(index, 1, data)
            }
        }
    }
}

export default new Info()