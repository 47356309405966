import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Input, 
    Button, 
    Divider,
    Checkbox,
    Row,
    Col,
} from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;

@inject('info')
@observer
class RoleEditForm extends React.Component {

    state = {
        role: {},
        auth: {}
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const roleId = this.props.roleId
        this.props.info.getProjects()
        if(!_.isUndefined(roleId)) {
            const data = await this.props.info.getRole(roleId)
            this.setState({
                role: data,
                auth: data.auth
            })
        }
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const roleId = this.props.roleId
                if(!_.isUndefined(roleId)) {
                    await this.props.info.updateRole(roleId, {role: values})
                } else {
                    await this.props.info.createRole({role: values})
                }
                history.push("/dashboard/roles")
            }
        });
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/roles")
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { projects } = this.props.info
        const { role, auth } = this.state

        return (
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 8 }}
                >
                    <Divider orientation="left">基础信息</Divider>
                    <FormItem 
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 8 }}
                        label="角色名称"
                    >
                        {getFieldDecorator('role_name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入角色名称',
                                },
                            ],
                            initialValue: role.role_name||""
                        })(<Input placeholder="请输入角色名称" />)}
                    </FormItem>
                    <Divider orientation="left">项目权限</Divider>
                        {
                            projects.map(project=>(

                                    <FormItem
                                        key={project.id}
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 16 }}
                                        label={project.name}
                                    >
                                    {getFieldDecorator(`auth[${project.sign}]`, {
                                        initialValue: auth[project.sign] || [],
                                    })(
                                            <Checkbox.Group style={{ width: '100%' }}>
                                                <Row>
                                                    {
                                                        project.auth.map((item, index)=> (
                                                            <Col key={item[0]} span={8}><Checkbox value={item[0]}>{item[1]}</Checkbox></Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Checkbox.Group>
                                        )}
                                    </FormItem>
                            ))
                        }
                    <FormItem
                        wrapperCol={{ span: 8, offset: 5 }}
                    >
                        <Button onClick={this.handleBack}>返回上一级</Button>
                        <Button type="primary" style={{marginLeft:'10px'}} htmlType="submit">提交</Button>
                    </FormItem>
                </Form>
        )
    }
}

export default  Form.create()(RoleEditForm);