import React from 'react';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { ContainerQuery } from 'react-container-query';
import { 
    Layout,
} from 'antd';

import Context from './MenuContext';
import logo from '../assets/logo.svg';
import SiderMenu from '../components/SiderMenu';
import Header from './HeaderView';



import RouteWithSubRoutes from "../router/routewithsubroutes"

import styles from './BasicLayout.module.less';

const { Content, Footer } = Layout;

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
};

export default class BasicLayout extends React.Component {

    getContext() {
        // 得到默认设置
        // const { location, breadcrumbNameMap } = this.props;
        return {
        };
    }

    getLayoutStyle = () => {
        const { fixSiderbar, isMobile, collapsed, layout } = this.props;
        if (fixSiderbar && layout !== 'topmenu' && !isMobile) {
        return {
            paddingLeft: collapsed ? '80px' : '256px',
        };
        }
        return null;
    };

    render() {
        const { routes } = this.props;

        const isTop = true

        const layout = (            
            <Layout>
                <SiderMenu 
                    logo={logo}
                />
                <Layout
                    style={{
                        minHeight: '100vh',
                    }}
                >
                    <Header style={{
                        padding: '0px',
                    }}> 
                    </Header>

                    <Content className={styles.content}>
                        {routes.map((route, i) => (<RouteWithSubRoutes key={i} {...route} />))}
                    </Content>
                    
                    <Footer />
                </Layout>
            </Layout>)



        return (
            <React.Fragment>
                <DocumentTitle title={"测试"}>
                    <ContainerQuery query={query}>
                        {params => (
                            <Context.Provider value={this.getContext()}>
                                <div className={classNames(params)}>{layout}</div>
                            </Context.Provider>
                        )}
                    </ContainerQuery>
                </DocumentTitle>
            </React.Fragment>
        )
    }
}