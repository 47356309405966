import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Input, 
    Button, 
} from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;

@inject('admin')
@observer
class UserSettingForm extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.admin.updateSetting({user: values})
            }
        })
    }

    handleBack = (e) => {
        e.preventDefault();
        history.goBack()
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { user } = this.props.admin || {}

        return (
            <Form onSubmit={this.handleSubmit} >
                <FormItem 
                    label="昵称"
                >
                    {getFieldDecorator('nickname', {
                        rules: [
                            {
                                required: true,
                                message: '请输入昵称',
                            },
                        ],
                        initialValue: user.nickname||"",
                    })(<Input placeholder="请输入昵称" />)}
                </FormItem>
                <FormItem
                    label="用户名"
                >
                    {getFieldDecorator('username', {
                        rules: [ {
                            required: true, message: 'Please input username',
                        }],
                        initialValue: user.username||""
                    })(
                        <Input placeholder="请输入用户名"/>
                    )}
                </FormItem>
                <FormItem
                    >
                    <Button onClick={this.handleBack}>返回</Button>
                    <Button type="primary" style={{marginLeft:'10px'}} htmlType="submit">提交</Button>
                </FormItem>
            </Form>
        )
    }
}


export default Form.create()(UserSettingForm);