
/**
 * Class to operate with error
 */
import { 
    message 
} from "antd";

import admin from '../store/admin'
import "antd/lib/message/style";
// 

class ErrorService {
    handleCommonError = (response) => {
        
        if(_.isUndefined(response) || response.status === 406) {
            message.error("需要从新登陆", 2);
            admin.logout()
            return null;
        }

        if (response.status !== 200 && response.status !== 201 && response.status !== 202  && response.status !== 204) {
            message.error(response.data.message, 2);
            return null;
        }

        if (response.status == 200 || response.status == 201 || response.status == 202  || response.status == 204) {
            if (!_.isEmpty(response.data["message"])) {
                message.success(response.data["message"], 2);
            }
        }

        return response.data["data"]
    }
}

export default new ErrorService()