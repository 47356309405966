import React from 'react';
import { inject, observer } from 'mobx-react'
import history from '../router/history'

// 这个组建主要是在初始化的时候做最初的跳转

@inject('admin')
@observer
class Init extends React.Component {

    componentDidMount() {
        if(this.props.admin.getStatus()) {
            history.push('/dashboard/announcement')
        }else{
            history.push('/login')
        }
    }

    render() {   
        return (
            <div />
        )
    }
}

export default Init;