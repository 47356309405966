import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'
import { 
    Menu, 
} from 'antd';
import HeaderSearch from 'ant-design-pro/lib/HeaderSearch';

import history from '../../router/history'

import styles from './GlobalHeader.module.less';

@inject('admin')
@observer
class GlobalHeader extends React.Component {

    render() {
        return (
            <div className={styles.header}>
                <div className={styles.light}>
                    <HeaderSearch
                        className={`${styles.action} ${styles.search}`}
                        placeholder="站内搜索"
                        dataSource={['搜索提示一', '搜索提示二', '搜索提示三']}
                        onSearch={(value) => {
                            console.log('input', value); // eslint-disable-line
                        }}
                        onPressEnter={(value) => {
                            console.log('enter', value); // eslint-disable-line
                        }}
                    />
                </div>
            </div>
    )}
}

export default GlobalHeader;
