import React from 'react';

import {
    Card,
    PageHeader
} from 'antd'

import RoleEditForm from '../../components/RoleEditForm'

export default class RoleNew extends React.Component {


    render() {
        return(
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="新角色"
                    subTitle="This is a subtitle"
                />
                <div
                    style={{
                        margin: '24px 24px 0',
                        width: '900px'
                    }}
                >
                    <Card bordered={false}>
                        <RoleEditForm  />
                    </Card>
                </div>
            </div>
        )
    }
}