
import Init from '../layout/Init'

import BasicLayout from '../layout/BasicLayout'

import Login from '../layout/Login'
import Register from '../layout/Register'

import IndexLayout from '../layout/IndexLayout'

import Info from '../pages/Info'

import UserList from '../pages/User/list'
import UserEdit from '../pages/User/edit'
import UserSetting from '../pages/User/setting'
import UserNew from '../pages/User/new'
import UserShow from '../pages/User/show'

import AuthList from '../pages/User/auth_list'
import AuthEdit from '../pages/User/auth_edit'

import ProjectList from '../pages/Project/list'
import ProjectEdit from '../pages/Project/edit'
import ProjectNew from '../pages/Project/new'

import RoleList from '../pages/Role/list'
import RoleEdit from '../pages/Role/edit'
import RoleNew from '../pages/Role/new'

const routes = [
    {
        path: "/",
        exact: true,
        component: Init,
    },
    {
        path: "/login",
        exact: true,
        component: Login,
    },
    {
        path: "/register",
        exact: true,
        component: Register,
    },
    {
        path: "/dashboard",
        private: true,
        component: BasicLayout,
        routes: [
            {
                path: "/dashboard",
                private: true,
                exact: true,
                component: Info,
            }, 
            {
                path: "/dashboard/users",
                component: IndexLayout,
                routes: [
                    {
                        path: "/dashboard/users/:id/edit",
                        private: true,
                        exact: true,
                        component: UserEdit,
                    },
                    {
                        path: "/dashboard/users/setting",
                        private: true,
                        exact: true,
                        component: UserSetting,
                    },
                    {
                        path: "/dashboard/users/new",
                        private: true,
                        exact: true,
                        component: UserNew,
                    },
                    {
                        path: "/dashboard/users",
                        private: true,
                        exact: true,
                        component: UserList,
                    },
                    {
                        path: "/dashboard/current_user",
                        private: true,
                        exact: true,
                        component: UserShow,
                    },
                    {
                        path: "/dashboard/users/users_auth",
                        private: true,
                        exact: true,
                        component: AuthList,
                    },
                    {
                        path: "/dashboard/users/:id/auth_edit",
                        private: true,
                        exact: true,
                        component: AuthEdit,
                    },
                ]
            },
            {
                path: "/dashboard/roles",
                component: IndexLayout,
                routes: [
                    {
                        path: "/dashboard/roles",
                        private: true,
                        exact: true,
                        component: RoleList,
                    },
                    {
                        path: "/dashboard/roles/:id/edit",
                        private: true,
                        exact: true,
                        component: RoleEdit,
                    },
                    {
                        path: "/dashboard/roles/new",
                        private: true,
                        exact: true,
                        component: RoleNew,
                    }
                ]
            },
                        {
                path: "/dashboard/projects",
                component: IndexLayout,
                routes: [
                    {
                        path: "/dashboard/projects",
                        private: true,
                        exact: true,
                        component: ProjectList,
                    },
                    {
                        path: "/dashboard/projects/:id/edit",
                        private: true,
                        exact: true,
                        component: ProjectEdit,
                    },
                    {
                        path: "/dashboard/projects/new",
                        private: true,
                        exact: true,
                        component: ProjectNew,
                    }
                ]
            },
        ]
    },

];

export default routes;